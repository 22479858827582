/*@jsxRuntime classic @jsx React.createElement @jsxFrag React.Fragment*/
import {useMDXComponents as _provideComponents} from "@mdx-js/react";
import React from "react";
function _createMdxContent(props) {
  const _components = Object.assign({
    p: "p",
    h2: "h2",
    a: "a",
    div: "div",
    strong: "strong",
    img: "img"
  }, _provideComponents(), props.components), {ImagePrefixer, LandingPageCta, MapInfo, ThreeColCta} = _components;
  if (!ImagePrefixer) _missingMdxReference("ImagePrefixer", true);
  if (!LandingPageCta) _missingMdxReference("LandingPageCta", true);
  if (!MapInfo) _missingMdxReference("MapInfo", true);
  if (!ThreeColCta) _missingMdxReference("ThreeColCta", true);
  return React.createElement(React.Fragment, null, React.createElement(_components.p, {
    className: "c-md-p"
  }, "hear.com is honored to be recognized for our cutting-edge hearing aids, industry innovation, and award-winning customer support."), "\n", React.createElement(_components.h2, {
    className: "c-md-h2",
    id: "2024",
    style: {
      position: "relative"
    }
  }, React.createElement(_components.a, {
    href: "#2024",
    "aria-label": "2024 permalink",
    className: "anchor before"
  }, React.createElement(_components.div, {
    dangerouslySetInnerHTML: {
      __html: "<svg aria-hidden=\"true\" focusable=\"false\" height=\"16\" version=\"1.1\" viewBox=\"0 0 16 16\" width=\"16\"><path fill-rule=\"evenodd\" d=\"M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z\"></path></svg>"
    }
  })), React.createElement(_components.strong, null, "2024")), "\n", React.createElement("hr"), "\n", React.createElement(ImagePrefixer, {
    imgSrc: "https://res.cloudinary.com/dhqvlsb3l/image/upload/f_auto,q_auto:best/v1/assets/na/seo-website/BIG_Innovation_2024.png"
  }, React.createElement(_components.h2, {
    className: "c-md-h2",
    id: "big-innovation-award",
    style: {
      position: "relative"
    }
  }, React.createElement(_components.a, {
    href: "#big-innovation-award",
    "aria-label": "big innovation award permalink",
    className: "anchor before"
  }, React.createElement(_components.div, {
    dangerouslySetInnerHTML: {
      __html: "<svg aria-hidden=\"true\" focusable=\"false\" height=\"16\" version=\"1.1\" viewBox=\"0 0 16 16\" width=\"16\"><path fill-rule=\"evenodd\" d=\"M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z\"></path></svg>"
    }
  })), React.createElement(_components.strong, null, "Big Innovation Award"))), "\n", React.createElement(_components.p, {
    className: "c-md-p"
  }, "The BIG Innovation Awards, presented by the Business Intelligence Group, recognize organizations, people and ideas changing how we experience the world."), "\n", React.createElement(_components.p, {
    className: "c-md-p"
  }, "Judged by a panel of notable experts – no writers or editors with limited experience – we consider any and all innovations deemed worthy of recognition. This year, the Integrated Xperience hearing aid platform has secured the recognition in the Healthcare category of the 2024 BIG Innovation Awards."), "\n", React.createElement(_components.p, {
    className: "c-md-p"
  }, "This innovative hearing aid system ensures unparalleled sound clarity and definition during noisy group conversations, even when the wearer or speakers are in motion."), "\n", React.createElement(LandingPageCta, {
    url: "/news/press-releases/2024-big-innovation-award/",
    copy: "READ MORE",
    classes: "c-cta c-cta--content"
  }), "\n", React.createElement(_components.h2, {
    className: "c-md-h2",
    id: "2023",
    style: {
      position: "relative"
    }
  }, React.createElement(_components.a, {
    href: "#2023",
    "aria-label": "2023 permalink",
    className: "anchor before"
  }, React.createElement(_components.div, {
    dangerouslySetInnerHTML: {
      __html: "<svg aria-hidden=\"true\" focusable=\"false\" height=\"16\" version=\"1.1\" viewBox=\"0 0 16 16\" width=\"16\"><path fill-rule=\"evenodd\" d=\"M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z\"></path></svg>"
    }
  })), React.createElement(_components.strong, null, "2023")), "\n", React.createElement("hr"), "\n", React.createElement(ImagePrefixer, {
    imgSrc: "https://res.cloudinary.com/dhqvlsb3l/image/upload/f_auto,q_auto:best/v1/assets/na/seo-website/big-innovation.png"
  }, React.createElement(_components.h2, {
    className: "c-md-h2",
    id: "big-innovation-award-1",
    style: {
      position: "relative"
    }
  }, React.createElement(_components.a, {
    href: "#big-innovation-award-1",
    "aria-label": "big innovation award 1 permalink",
    className: "anchor before"
  }, React.createElement(_components.div, {
    dangerouslySetInnerHTML: {
      __html: "<svg aria-hidden=\"true\" focusable=\"false\" height=\"16\" version=\"1.1\" viewBox=\"0 0 16 16\" width=\"16\"><path fill-rule=\"evenodd\" d=\"M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z\"></path></svg>"
    }
  })), React.createElement(_components.strong, null, "Big Innovation Award"))), "\n", React.createElement(_components.p, {
    className: "c-md-p"
  }, "The BIG Innovation Awards, presented by the Business Intelligence Group, recognize organizations and people who bring new ideas to life."), "\n", React.createElement(_components.p, {
    className: "c-md-p"
  }, "Whether big or small, these ideas change the way we experience the world. This year, hear.com received not one but ", React.createElement(_components.strong, null, "two"), " BIG Innovation Awards for the following:"), "\n", React.createElement("p", {
    className: "c-title-ribbon"
  }, "hear.com Horizon hearing aids"), "\n", React.createElement(_components.p, {
    className: "c-md-p"
  }, "The Horizon hearing aids by hear.com were selected by judges for their cutting-edge sound technology and stylish design."), "\n", React.createElement("p", {
    className: "c-title-ribbon"
  }, "Tele-Audiology solution"), "\n", React.createElement(_components.p, {
    className: "c-md-p"
  }, "Clinic-In-A-Box™ , ", React.createElement(_components.a, {
    href: "/teleaudiology/",
    className: "c-md-a"
  }, "hear.com’s tele-audiology solution"), ", received the BIG Innovation Award for making hearing care more accessible to people everywhere."), "\n", React.createElement(LandingPageCta, {
    url: "/news/press-releases/hear-com-wins-two-big-innovation-awards/",
    copy: "READ MORE",
    classes: "c-cta c-cta--content"
  }), "\n", React.createElement(_components.h2, {
    className: "c-md-h2",
    id: "2021",
    style: {
      position: "relative"
    }
  }, React.createElement(_components.a, {
    href: "#2021",
    "aria-label": "2021 permalink",
    className: "anchor before"
  }, React.createElement(_components.div, {
    dangerouslySetInnerHTML: {
      __html: "<svg aria-hidden=\"true\" focusable=\"false\" height=\"16\" version=\"1.1\" viewBox=\"0 0 16 16\" width=\"16\"><path fill-rule=\"evenodd\" d=\"M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z\"></path></svg>"
    }
  })), React.createElement(_components.strong, null, "2021")), "\n", React.createElement("hr"), "\n", React.createElement(ImagePrefixer, {
    imgSrc: "https://res.cloudinary.com/dhqvlsb3l/image/upload/f_auto,q_auto:best/v1/assets/na/seo-website/newsweek-logo-v2.png"
  }, React.createElement(_components.h2, {
    className: "c-md-h2",
    id: "newsweek-best-customer-service-award",
    style: {
      position: "relative"
    }
  }, React.createElement(_components.a, {
    href: "#newsweek-best-customer-service-award",
    "aria-label": "newsweek best customer service award permalink",
    className: "anchor before"
  }, React.createElement(_components.div, {
    dangerouslySetInnerHTML: {
      __html: "<svg aria-hidden=\"true\" focusable=\"false\" height=\"16\" version=\"1.1\" viewBox=\"0 0 16 16\" width=\"16\"><path fill-rule=\"evenodd\" d=\"M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z\"></path></svg>"
    }
  })), React.createElement(_components.strong, null, "Newsweek Best Customer Service Award"))), "\n", React.createElement(_components.p, {
    className: "c-md-p"
  }, "America’s Best Customer Service Award is presented by Newsweek to companies that consistently provide their customers with best-in-class experiences and support."), "\n", React.createElement(_components.p, {
    className: "c-md-p"
  }, "At hear.com, we are on a mission to help people hear well to live well. A large part of that involves supporting our customers on every step of their journey to better hearing. These efforts have been recognized by Newsweek, and received America’s Best Customer Service Award in \n2021 and 2022."), "\n", React.createElement(LandingPageCta, {
    url: "https://www.newsweek.com/americas-best-customer-service-2021",
    copy: "READ MORE",
    classes: "c-cta c-cta--content"
  }), "\n", React.createElement(_components.h2, {
    className: "c-md-h2",
    id: "2019",
    style: {
      position: "relative"
    }
  }, React.createElement(_components.a, {
    href: "#2019",
    "aria-label": "2019 permalink",
    className: "anchor before"
  }, React.createElement(_components.div, {
    dangerouslySetInnerHTML: {
      __html: "<svg aria-hidden=\"true\" focusable=\"false\" height=\"16\" version=\"1.1\" viewBox=\"0 0 16 16\" width=\"16\"><path fill-rule=\"evenodd\" d=\"M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z\"></path></svg>"
    }
  })), React.createElement(_components.strong, null, "2019")), "\n", React.createElement("hr"), "\n", React.createElement(ImagePrefixer, {
    imgSrc: "https://res.cloudinary.com/dhqvlsb3l/image/upload/f_auto,q_auto:best/v1/assets/na/seo-website/red-dot-v2.png"
  }, React.createElement(_components.h2, {
    className: "c-md-h2",
    id: "red-dot-design-award",
    style: {
      position: "relative"
    }
  }, React.createElement(_components.a, {
    href: "#red-dot-design-award",
    "aria-label": "red dot design award permalink",
    className: "anchor before"
  }, React.createElement(_components.div, {
    dangerouslySetInnerHTML: {
      __html: "<svg aria-hidden=\"true\" focusable=\"false\" height=\"16\" version=\"1.1\" viewBox=\"0 0 16 16\" width=\"16\"><path fill-rule=\"evenodd\" d=\"M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z\"></path></svg>"
    }
  })), React.createElement(_components.strong, null, "Red Dot Design Award"))), "\n", React.createElement(_components.p, {
    className: "c-md-p"
  }, "The Red Dot Award recognizes exceptional design for products, brands, communications, and concepts. The distinction “Red Dot” has become established internationally as one of the most sought-after seals of quality for good design."), "\n", React.createElement(_components.p, {
    className: "c-md-p"
  }, "hear.com’s Horizon Style hearing aids received the Red Dot Design Award for their sleek, modern design that is nearly invisible behind the ear."), "\n", React.createElement(LandingPageCta, {
    url: "https://www.red-dot.org/",
    copy: "READ MORE",
    classes: "c-cta c-cta--content"
  }), "\n", React.createElement("hr"), "\n", React.createElement(_components.p, {
    className: "c-md-p"
  }, React.createElement(_components.img, {
    src: "https://res.cloudinary.com/dhqvlsb3l/image/upload/f_auto,q_auto:best/v1//assets/na/seo-website/trust-logos-awards.png",
    alt: "Google reviews logo, Accredited Business logo and Trustpilot logo",
    className: "c-md-img"
  })), "\n", React.createElement(MapInfo), "\n", React.createElement(_components.h2, {
    className: "c-md-h2",
    id: "resources",
    style: {
      position: "relative"
    }
  }, React.createElement(_components.a, {
    href: "#resources",
    "aria-label": "resources permalink",
    className: "anchor before"
  }, React.createElement(_components.div, {
    dangerouslySetInnerHTML: {
      __html: "<svg aria-hidden=\"true\" focusable=\"false\" height=\"16\" version=\"1.1\" viewBox=\"0 0 16 16\" width=\"16\"><path fill-rule=\"evenodd\" d=\"M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z\"></path></svg>"
    }
  })), "Resources"), "\n", React.createElement(_components.p, {
    className: "c-md-p"
  }, "Learn everything you need to know about hearing aids and hearing loss."), "\n", React.createElement(ThreeColCta, {
    ImgSrc1: "https://res.cloudinary.com/dhqvlsb3l/image/upload/f_auto,q_auto:best/v1/assets/na/seo-website/women-with-tablet.jpg",
    ContentTitle1: "Hearing Test",
    ContentCopy1: "Check how is your hearing in less than 5 minutes.",
    Url1: "/hearing-test/",
    ImgSrc2: "https://res.cloudinary.com/dhqvlsb3l/image/upload/f_auto,q_auto:best/v1/assets/na/seo-website/hearing-aids-behind-ear-blur.jpg",
    ContentTitle2: "Value & Price",
    ContentCopy2: "Are hearing aids worth the cost? We asked an expert.",
    Url2: "/hearing-aids/prices/",
    ImgSrc3: "https://res.cloudinary.com/dhqvlsb3l/image/upload/f_auto,q_auto:best/v1/assets/na/seo-website/go-ax-grey-blur.jpg",
    ContentTitle3: "Hearing Aids",
    ContentCopy3: "Connect you to the world around you with our wide range of devices.",
    Url3: "/hearing-aids/"
  }));
}
function MDXContent(props = {}) {
  const {wrapper: MDXLayout} = Object.assign({}, _provideComponents(), props.components);
  return MDXLayout ? React.createElement(MDXLayout, props, React.createElement(_createMdxContent, props)) : _createMdxContent(props);
}
export default MDXContent;
function _missingMdxReference(id, component) {
  throw new Error("Expected " + (component ? "component" : "object") + " `" + id + "` to be defined: you likely forgot to import, pass, or provide it.");
}
